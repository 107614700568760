import {
  createInput, createNumber, createDatePicker, createSelect, formEvetns, msgBox,
} from 'nb-modules';
import {
  clone, merge, isEmpty, formatTimes, lazy,
} from 'ldx-utils';
import dayjs from 'dayjs';
import { getChildRegion } from '@/com';

export function tableInfo(vm) {
  return {
    ref: 'nbTable',
    config: {
      table: {
        stripe: false,
        showHandle: true,
        attrs: { 'highlight-current-row': true },
        loadingBgColor: 'rgba(255, 255, 255, 0.8)',
        loadRender: (h) => h('i', { class: 'el-icon-loading', style: 'color: #409EFF; font-size: 40px' }),
      },
      pagination: {
        currentPageKey: 'currentPage',
      },
    },
    request: vm.getData,
  };
}

export function _msgBox(options = {}, message) {
  msgBox.call(this, '$confirm', { message, title: '提示' }, () => {
    return new Promise((resolve) => {
      lazy.call(this, async () => {
        const res = await this.$axios(options);
        if (res.code === 0) {
          this.$message({ type: 'success', message: '操作成功' });
          this.$refs.nbTable.getData();
          resolve();
        }
      });
    });
  });
}

export function modifyBatchOrder() {
  this.tableInfo = merge(tableInfo(this), {
    config: {
      table: {
        showIndex: true,
        showHandle: false,
      },
      pagination: { show: false },
    },
    columns: [
      { prop: 'orderNo', label: '订单号' },
      { prop: 'logisticName', label: '原物流公司' },
      { prop: 'logisticNo', label: '原运单号' },
      { isSlot: 'newLogisticName', header: 'header-newLogisticName', label: '新流公司' },
      { isSlot: 'newLogisticNo', header: 'header-newLogisticNo', label: '新运单号' },
      { isSlot: 'newMemo', header: 'header-newMemo', label: '备注' },
      { isSlot: 'memoImg', header: 'header-memoImg', label: '备注图品' },
    ],
  });
}
export function modifySingleOrder() {
  this.formEvents = {
    onreset: this.hide,
    onsubmit: this.onsubmit,
  };
  this.formInfo = {
    ref: 'nbForm',
    options: {
      formAttrs: { 'label-width': '80px' },
      resetButton: { type: 'default', text: '取消' },
    },
  };
}

export function useAlreadyOrder() {
  this.formEvents = formEvetns(this);

  const end = Date.now();
  const start = end - 30 * 86400 * 1000;
  this.formInfo = {
    ref: 'nbForm',
    options: {
      type: 'search',
      // renderBtn: h => {
      //   return h('el-checkbox', {
      //     style: 'margin-left: 10px;',
      //     attrs: {type: 'primary', value: this.isSame},
      //     on: {
      //       input: res => this.isSame = res
      //     }
      //   }, '收货信息相同');
      // }
    },
    columns: [
      createInput('订单编号', 'orderNo', {
        attrs: { placeholder: '请输入订单编号' },
      }),
      {
        slot: 'logisticCode', label: '物流公司', key: 'logisticCode', value: '',
      },
      createInput('运单号', 'logisticNo', {
        attrs: { placeholder: '请输入运单号' },
      }),
      createDatePicker('发货时间', 'times', {
        value: [start, end],
        attrs: { placeholder: '请选择发货时间' },
      }),
      createInput('收件人姓名', 'receiverName', {
        attrs: { placeholder: '请输入完整收件人名称' },
      }),
      createInput('收件人手机', 'mobile', {
        attrs: { placeholder: '请输入收件人手机号' },
      }),
      {
        use: 'nb-area',
        label: '地址',
        key: 'areas',
        value: [],
        attrs: {
          length: 3,
          placeholder: '请选择地址',
          request: (id) => getChildRegion.call(this, id),
        },
      },
    ],
  };

  this.tableInfo = merge(tableInfo(this), {
    config: {
      table: {
        showSelection: true,
        'handle-attrs': { width: 200 },
        selection: {
          selectable: (row) => (!!row.canModify),
        },
        events: {
          'selection-change': this.handleSelectionChange,
        },
      },
    },
    columns: [
      { prop: 'orderNo', label: '订单号' },
      { prop: 'receiveInfo', label: '收货信息' },
      { prop: 'logisticName', label: '物流公司' },
      { prop: 'logisticNo', label: '运单号' },
      { prop: 'memo', label: '备注' },
      { label: '发货时间', render: (h, { row }) => h('div', formatTimes(dayjs, row.deliverTime)) },
    ],
  });
}

export function youxuanLogPage() {
  this.formEvents = formEvetns(this);

  this.formInfo = {
    ref: 'nbForm',
    options: {
      type: 'search'
    },
    columns: [
      createInput('关联商品ID', 'goodsId', {
        attrs: { placeholder: '请输入商品ID' },
      }),
      createDatePicker('操作时间', 'times', {
        attrs: { placeholder: '请选择' },
      }),
    ],
  };

  this.tableInfo = merge(tableInfo(this), {
    config: {
      table: {
        showHandle: false
      },
    },
    columns: [
      { prop: 'goodsId', label: '关联商品ID' },
      { prop: 'relationSupplierId', label: '供应商ID' },
      { prop: 'relationSupplierName', label: '供应商名称' },
      { prop: 'optTypeStr', label: '操作' },
      { prop: 'createName', label: '操作账号' },
      { label: '操作时间', render: (h, { row }) => h('div', formatTimes(dayjs, row.createTime)) },
      { isSlot: 'details', label: '详情' },
    ],
  });
}

export function logDetailsTablle() {
  this.tableInfo = merge(tableInfo(this), {
    config: {
      table: {
        showHandle: false
      },
      pagination: {
        show: false
      },
    },
    columns: [
      { prop: 'specValue', label: '规格' },
      { prop: 'supplyPrice', label: '订货价（元）' },
      { prop: 'marketPrice', label: '市场价（元）' },
      { prop: 'sellPrice', label: '销售价（元）' },
      { prop: 'minBuy', label: '起购量（件）' },
      { prop: 'stockQuantity', label: '库存（件）' },
      { prop: 'stockQuantityWarning', label: '预警库存（件）' },
      { isSlot: 'imgs', label: '规格图' },
      { prop: 'skuNo', label: '规格编码' },
    ]
  });
}

export function useMsgList() {
  this.tableInfo = merge(tableInfo(this), {
    config: {
      pagination: {
        show: this.needPaging,
      },
    },
    columns: [
      {
        label: '通知标题',
        width: '250',
        render: (h, { row }) => {
          const textEl = h('div', { style: 'flex: 1; text-align: left;', class: 'elllipsis' }, row.title);
          const iconEl = h('div', { class: 'no-read', style: `background: ${!row.isRead ? 'red' : ''}` });
          return h('div', { style: 'display: flex; align-items: center;' }, [iconEl, textEl]);
        },
      },
      {
        label: '通知内容',
        render: (h, { row }) => {
          const text = this.messageType === 1 || this.messageType === 4 ? row.messageDesc : row.content;
          return h('div', { style: 'text-align: left', class: 'elllipsis', domProps: { innerHTML: text } });
        },
      },
      { label: '通知时间', width: '140', render: (h, { row }) => h('div', formatTimes(dayjs, row.sendTime)) },
    ],
  });
}

// 智齿客服列表
export function useCustomerServiceList() {
  this.formInfo = {
    ref: 'nbForm',
    options: {
      formAttrs: { 'label-width': '120px' },
      resetButton: { text: '取消' },
    },
  };
  this.formEvents = {
    onreset: () => {
      this.show = false;
    },
    onsubmit: this.onsubmit,
  };
  this.tableInfo = merge(tableInfo(this), {
    config: {
      table: {
        'handle-attrs': { width: '200' },
      },
      pagination: { show: false },
    },
    columns: [
      { prop: 'agentName', label: '已开通客服席位（客服名称）' },
      { prop: 'createTime', label: '创建日期' },
      { prop: 'supplierUserName', label: '关联子账号名称' },
      { prop: 'supplierPhone', label: '关联手机号' },
    ],
  });
}

export function merchantsDetailConfig() {
  const enterpriseValid = (rule, value, callback) => {
    if (!value.length || !value[0].suppliersImagePath) callback(new Error());
    else callback();
  };
  const legalPersonValid = (rule, value, callback) => {
    if (!value.length || !value[0].suppliersImagePath || !value[1].suppliersImagePath) callback(new Error());
    else callback();
  };
  const areasValid = (rule, value, callback) => {
    if (!value.length) callback(new Error(rule.msg[0]));
    else if (value.length === 1) callback(new Error(rule.msg[1]));
    else if (value.length === 2) callback(new Error(rule.msg[2]));
    else callback();
  };
  const goodsTypesValid = (rule, value, callback) => {
    const n = this.$refs.goodsTypes.valid();
    if (n) {} else if (!value.length) callback(new Error(rule.message));
    else callback();
  };

  const { id, type } = this.$route.query;
  this.supplierId = id;
  this.type = type || 'add';
  this.isRead = type === 'read'; // read: 查看, edit: 编辑

  const configs = {
    ref: 'nbForm',
    options: {
      showConfirmButton: !this.isRead,
      showResetButton: false,
      confirmButton: {
        text: '提交审核',
      },
      formAttrs: {
        'label-width': '140px',
        size: 'mini',
      },
    },
    rules: {
      enterprise: [{ validator: enterpriseValid, message: '请上传营业执照', required: true }],
      legalPerson: [{ validator: legalPersonValid, message: '请上传身份证，正反面', required: true }],
      areas: [
        {
          validator: areasValid,
          trigger: 'change',
          required: true,
          msg: ['请选择地区', '请选择市和区', '请选择区'],
        },
      ],
      goodsTypes: [{
        validator: goodsTypesValid, trigger: 'change', message: '请上传相关经营资质', required: true,
      }],
    },
    columns: [
      {
        use: 'el-input',
        label: '商家名称',
        key: 'supplierName',
        value: '',
        required: !this.isRead,
        attrs: { placeholder: '请输入商家名称', readonly: this.isRead },
      },
      {
        use: 'nb-area',
        label: '所属地区',
        key: 'areas',
        value: [],
        required: !this.isRead,
        attrs: {
          disabled: this.isRead,
          class: this.isRead ? 'readonly' : '',
          request: (id) => getChildRegion.call(this, id),
        },
        formItemAttrs: { ref: 'areas' },
      },
      {
        use: 'el-checkbox-group',
        label: '经营范围',
        key: 'supplierGoodsTypes',
        value: [],
        required: !this.isRead,
        children: 'el-checkbox',
        attrs: { disabled: true, class: 'readonly' },
        request: this._getTypes,
        events: {
          change: (value) => {
            if (value.length > this.checks.length) { // 增加选项
              value.forEach((v) => {
                if (!this.checks.includes(v)) {
                  const item = this.goodsTypes.filter((item) => item.suppliersType === v)[0];
                  this.$refs.goodsTypes.createTypeFn(item);
                }
              });
            } else { // 减少选项
              if (!value.length) this.$refs.goodsTypes.reset();
              else {
                this.checks.forEach((v) => {
                  if (!value.includes(v)) {
                    const item = this.goodsTypes.filter((item) => item.suppliersType === v)[0];
                    this.$refs.goodsTypes.del(item);
                  }
                });
              }
            }
            this.checks = clone(value);
          },
        },
      },
      {
        use: 'el-input',
        label: '法人姓名',
        key: 'legalIdName',
        value: '',
        required: !this.isRead,
        attrs: { placeholder: '请输入法人姓名', readonly: this.isRead },
      },
      {
        use: 'el-input',
        label: '身份证',
        key: 'legalIdCode',
        value: '',
        required: !this.isRead,
        attrs: { placeholder: '请输入身份证号码', readonly: this.isRead },
      },
      {
        use: 'el-input',
        label: '商家注册地址',
        key: 'address',
        value: '',
        required: !this.isRead,
        attrs: { placeholder: '请输入商家注册地址', readonly: this.isRead },
      },
      {
        use: 'el-input',
        label: '统一社会信用代码',
        key: 'uscc',
        value: '',
        required: !this.isRead,
        attrs: { placeholder: '请输入统一社会信用代码', readonly: this.isRead },
      },
      {
        use: 'el-input',
        label: '商品生产地址',
        key: 'produceAddress',
        value: '',
        required: !this.isRead,
        attrs: { placeholder: '请输入商品生产地址', readonly: this.isRead },
      },
      {
        use: 'el-input',
        label: '联系人',
        key: 'contact',
        value: '',
        required: !this.isRead,
        attrs: { placeholder: '请输入联系人姓名', readonly: this.isRead },
      },
      {
        use: 'el-input',
        label: '注册电话',
        key: 'mobile',
        value: '',
        required: !this.isRead,
        hidden: this.pageType === 'newpage',
        attrs: { readonly: this.isRead, disabled: !this.isRead },
      },
      {
        slot: 'enterprise',
        label: '企业资质',
        key: 'enterprise',
        formItemClass: 'upload-formItem',
        required: !this.isRead,
        formItemAttrs: { ref: 'enterprise' },
        value: [
          { suppliersImagePath: '', suppliersImageType: '0-3', suppliersType: 0 },
        ],
      },
      {
        slot: 'legalPerson',
        label: '法人身份证',
        key: 'legalPerson',
        formItemClass: 'upload-formItem',
        required: !this.isRead,
        formItemAttrs: { ref: 'legalPerson' },
        value: [
          { suppliersImagePath: '', suppliersImageType: '0-1', suppliersType: 0 },
          { suppliersImagePath: '', suppliersImageType: '0-2', suppliersType: 0 },
        ],
      },
      {
        slot: 'goodsTypes',
        label: '经营资质',
        key: 'goodsTypes',
        value: [],
        formItemAttrs: { ref: 'goodsTypes' },
        formItemClass: 'upload-formItem',
        required: !this.isRead,
      },
      {
        slot: 'honors', label: '荣誉证书', key: 'honors', value: [], formItemClass: 'upload-formItem',
      },
    ],
  };

  this.formEvents = {
    onsubmit: this.onsubmit,
    onreset: this.onreset,
  };

  return configs;
}

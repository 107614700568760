export function onlyValue() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16);
}

export function sleep(fn, options = {}) {
  const throttleKey = options.throttleKey || 'sleepTimer';

  if (fn[throttleKey]) {
    if (options.debounce) {
      clearTimeout(fn[throttleKey]);
      delete fn[throttleKey];
    } else {
      return;
    }
  }

  fn[throttleKey] = setTimeout(() => {
    delete fn[throttleKey];
    const args = options.args || [];
    fn(...args);
  }, options.delay || 300);
}

// 获取省市区
export function getChildRegion(id) {
  return new Promise(async (resolve) => {
    const res = await this.$axios.get(this.$api.commonNew.getChildRegion, { params: { regionCode: id } });
    if (res.code === 0) {
      const children = res.data.map((item) => ({ value: item.regionCode, label: item.regionName }));
      resolve(children);
    }
  });
}
// 获取展馆
export function getSiteInfo() {
  return new Promise(async (resolve) => {
    const res = await this.$axios.get(this.$api.promApply.all_site_list, {
      params: {
        siteType: 1,
      },
    });
    if (res.code === 0) {
      let str = JSON.stringify(res.data);
      str = str.replaceAll('[]', '""');
      this.options = JSON.parse(str);
      resolve(JSON.parse(str));
    }
  });
}

export function trim(str) {
  return typeof str === 'string' ? str.replace(/^\s+|\s+$/g, '') : str;
}

export function setTrim(obj) {
  const res = {};
  for (let key in obj) {
    res[key] = trim(obj[key]);
  }
  return res;
}
